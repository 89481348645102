<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true"  v-show="showSearch" class="query-form">
      <el-form-item label="设备号" prop="device">
        <el-input
          v-model="queryParams.device"
          placeholder="请输入设备号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="运单号" prop="shippingNoteNumber">
        <el-input
          v-model="queryParams.shippingNoteNumber"
          placeholder="请输入运单号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="运单状态" prop="shippingStatus">
        <el-select v-model="queryParams.shippingStatus" placeholder="请选择运单状态" clearable size="small">
          <el-option
            v-for="dict in shippingStatuses"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="司机电话" prop="driverPhone">
        <el-input
          v-model="queryParams.driverPhone"
          placeholder="请输入司机电话"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
<el-form-item label="创建日期" prop="createDate">
       <el-date-picker
                  :default-value="defaultValue"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="queryParams.timerScope"
                ></el-date-picker>

</el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
<!--        <el-button-->
<!--          type="danger"-->
<!--          plain-->
<!--          icon="el-icon-delete"-->
<!--          size="medium"-->
<!--          :loading="exportLoading"-->
<!--          @click="open11 = true"-->
<!--        >批量作废-->
<!--        </el-button>-->

        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="medium"
            :loading="exportLoading"
            @click="batchCancel"
        >作废
        </el-button>

      </el-form-item>
    </el-form>

    <el-table
      border
      stripe
      v-loading="loading"
      ref="shippingListTable"
      :data="shippingList"
      @selection-change="handleSelectionChange"
    >
       <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" fixed align="center" type="index" />

      <el-table-column label="运单号" align="center" prop="shippingNoteNumber" width="250">
      </el-table-column>
      <el-table-column label="分段分单号" align="center" prop="serialNumber" width="100" />
      <el-table-column label="设备号" align="center" prop="device" width="200">

      </el-table-column>
      <el-table-column label="客户单号" align="center" prop="transportBillNumber" width="250">
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime" width="150">
        <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
      </el-table-column>
      <el-table-column label="运单状态" align="center" prop="shippingStatus"  width="200">
        <template slot-scope="scope">
          <!-- {{ formatStatus(scope.row.shippingStatus) }} -->
          <dict-tag :options="shippingStatuses" :value="scope.row.shippingStatus"/>
        </template>
      </el-table-column>
      <el-table-column label="司机姓名" align="center" prop="driverName"  width="120"/>
      <el-table-column label="司机电话" align="center" prop="driverPhone"  width="120"/>
      <el-table-column label="车牌号" align="center" prop="vehicleNumber" width="150" >
      </el-table-column>
      <el-table-column label="装货地址" align="center" prop="uploadAddress" width="200" />
      <el-table-column label="装货地触发时间" align="center" prop="uploadTime" width="150">
        <template slot-scope="scope">{{ scope.row.uploadTime | formatDate }}</template>
      </el-table-column>
      <el-table-column label="卸货地址" align="center" prop="receiptAddress" width="200" />
      <el-table-column label="卸货地触发时间" align="center" prop="receiptTime" width="150">
        <template slot-scope="scope">{{ scope.row.receiptTime | formatDate }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed align="center" width="150" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            icon="el-icon-location"
            @click="showTrack(scope.row)"
          >运单轨迹</el-button>
          <el-dropdown size="small" @command="(command) => handleCommand(command, scope.row)">
            <span class="el-dropdown-link">
              更多<i class="el-icon-d-arrow-right el-icon--left"></i>

            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="!scope.row.vehicleNumber" command="toVehicle" icon="el-icon-view">查看车辆绑定</el-dropdown-item>
              <el-dropdown-item :disabled="!scope.row.device" command="toDevice" icon="el-icon-view">查看设备绑定</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>=0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      :page-sizes="[10, 20, 50, 100, 200,500, 1000, 2000]"
      @pagination="getList"
    >
      <template v-slot:end>
        <el-button
            type="warning"
            icon="el-icon-download"
            size="small"
            :loading="exportLoading"
            @click="handleExportLocal"
          >导出当前页
          </el-button>
      </template>
    </pagination>

    <!--查看轨迹-->
    <baidu-map-track ref="mapTrack" v-model="dialogMapTrackVisible"></baidu-map-track>
       <batch-Delete v-model="open11"  ref="batchDelete"/>
  </div>
</template>

<script>
const { VUE_APP_API_HOST, VUE_APP_API_QIYE_HOST } = process.env;
import { listShipping, exportShippingLocal } from "@/api/schedule/shipping";
import BaiduMapTrack from './components/BaiduMap/track';
import Pagination from "./components/pagination.vue"
import { formateDate } from '@/libs/util'
import DictTag from "./components/dictTag.vue"
import batchDelete from './components/batchDelete';
import * as XLSX from 'xlsx';
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: "ShippingList",
  components: {Pagination, BaiduMapTrack, DictTag,batchDelete},
  props: {
    number: String
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      open11: false,
      // 设备围栏关联表表格数据
      shippingList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device: null,
        driverPhone: null,
        shippingStatus: null,
        shippingNoteNumber: null,
        transportBillNumber:null,
        timerScope:[],
      },
      shippingStatuses: [
        //0 未开始 1:进行中(开始围栏已触发) 2:已触发结束(结束围栏已触发) 3: 非触发结束 4: 手动作废
        { label: '未开始', value: 0, type: 'info' },
        { label: '进行中(开始围栏已触发)', value: 1, type: 'primary' },
        { label: '已触发结束(结束围栏已触发)', value: 2, type: 'success' },
        { label: '非触发结束', value: 3, type: 'warning' },
        { label: '手动作废', value: 4, type: 'danger' }
      ],
      dialogMapTrackVisible: false
    };
  },
  created() {
    this.init();
  },
  methods: {


    init(){
      if (this.number) {
        this.queryParams.shippingNoteNumber = this.number
      }
      this.getList()
    },
    // 将勾选的生成为excel
    batchCancel() {

      if (this.selectedRows.length === 0) {
        this.$message.warning('请勾选需要作废的运单');
        return;
      }


      const ws = XLSX.utils.json_to_sheet(this.selectedRows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '运单作废');

      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });

      const formData = new FormData();
      formData.append('file', blob, 'shipping.xlsx');

      this.uploadExcel(formData);
    },
    // 上传生成的excel
    async uploadExcel(formData) {

      try {
        await this.uploadFile(formData, "作废运单成功", "作废运单失败");
      } catch (error) {
        console.error("文件上传失败", error);
      }
    },


    // 通用的文件上传方法
    uploadFile(file, successMessage, errorMessage) {
      let header = {
        'content-type': 'application/json',
        'token': Cookies.get('token') || '',
      };

      return axios({
        method: 'POST',
        url: VUE_APP_API_QIYE_HOST + "/shipping_info/batchDelete",
        data: file,
        headers: header,
        // withCredentials: true // 发送凭证
        // responseType: 'blob'
      }).then(response => {
                console.log('Response:', response);

                if (response.data && response.data.code === 0) {
                  this.$alert(response.data.msg, successMessage, { dangerouslyUseHTMLString: true });
                  this.init()
          }else {
              // 当 code 不是 0 时，抛出一个错误
              throw new Error(response.data.msg || 'Unknown error');
            }
          })
          .catch(error => {
            console.error(error);
            this.$alert(errorMessage, "错误", { dangerouslyUseHTMLString: true });
          });
    },

    // 取消按钮
    cancel() {
      this.$emit("input", false);
    },

    // 下载模板操作
    uploading(data) {
      let file = data.file;
      let params = new FormData();
      params.append('file', file);

      this.uploadFile(file, "批量作废运单成功", "批量作废运单失败");
    },

    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },



    /** 查询设备围栏关联表列表 */
    getList() {
      this.loading = true;
      let form = Object.fromEntries(
        Object.entries(this.queryParams).map(
          ([k, v]) => [k, v === '' ? null : v]
        )
      )
      listShipping(form).then(response => {
        this.shippingList = response.data.list;
        this.total = response.data.count;
      }).finally(()=> {
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 导出按钮操作 */
    handleExportLocal() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出当前页的运单数据项？').then(() => {
        this.exportLoading = true;
        let fileName = '生成运单数据_' + Object.keys(this.queryParams).filter(k => !!this.queryParams[k] && this.queryParams[k] !== null).map(k => k + '_' + this.queryParams[k]).join('-') + '_' + formateDate(new Date().getTime());
        return exportShippingLocal(this.$refs.shippingListTable.$el, fileName, 1);
      }).then(response => {
        this.$Message.success(response.msg);
      }).catch(error => {
        this.$Message.error(error.message || error);
      }).finally(() => {
        this.exportLoading = false;
      })
    },
    formatStatus(status) {
      let labels = this.shippingStatuses.filter(s => s.value === status).map(s => s.label)
      return labels.length ? labels[0] : `未知（${status}）`
    },
    toDevice(row) {
      this.$router.push({path: '/schedule/device', query: {device: row.device}})
    },
    toVehicle(row) {
      this.$router.push({path: '/schedule/device', query: {vehicle: row.vehicleNumber}})
    },

    // cancel(row) {
    //   console.log(row)
    //  alert( '作废')
    // },
    handleCommand(command, row) {
      switch(command) {
        case 'toDevice':
          this.toDevice(row);
          return;
        case 'toVehicle':
          this.toVehicle(row);
          return;
        case 'cancel':
          this.cancel(row);
          return;
      }
    },
    showTrack(row) {
      this.dialogMapTrackVisible = true;
      this.$nextTick(() => {
        this.$refs.mapTrack.init(row);
      });
    },
  }
};
</script>
