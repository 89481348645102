var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          staticClass: "query-form",
          attrs: { model: _vm.queryParams, inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "设备号", prop: "device" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入设备号",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.queryParams.device,
                  callback: function($$v) {
                    _vm.$set(_vm.queryParams, "device", $$v)
                  },
                  expression: "queryParams.device"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "运单号", prop: "shippingNoteNumber" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入运单号",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.queryParams.shippingNoteNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.queryParams, "shippingNoteNumber", $$v)
                  },
                  expression: "queryParams.shippingNoteNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "运单状态", prop: "shippingStatus" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择运单状态",
                    clearable: "",
                    size: "small"
                  },
                  model: {
                    value: _vm.queryParams.shippingStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.queryParams, "shippingStatus", $$v)
                    },
                    expression: "queryParams.shippingStatus"
                  }
                },
                _vm._l(_vm.shippingStatuses, function(dict) {
                  return _c("el-option", {
                    key: dict.value,
                    attrs: { label: dict.label, value: dict.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "司机电话", prop: "driverPhone" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入司机电话",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.queryParams.driverPhone,
                  callback: function($$v) {
                    _vm.$set(_vm.queryParams, "driverPhone", $$v)
                  },
                  expression: "queryParams.driverPhone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建日期", prop: "createDate" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-value": _vm.defaultValue,
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.queryParams.timerScope,
                  callback: function($$v) {
                    _vm.$set(_vm.queryParams, "timerScope", $$v)
                  },
                  expression: "queryParams.timerScope"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "small"
                  },
                  on: { click: _vm.handleQuery }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "small" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    plain: "",
                    icon: "el-icon-delete",
                    size: "medium",
                    loading: _vm.exportLoading
                  },
                  on: { click: _vm.batchCancel }
                },
                [_vm._v("作废 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "shippingListTable",
          attrs: { border: "", stripe: "", data: _vm.shippingList },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "序号", fixed: "", align: "center", type: "index" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "运单号",
              align: "center",
              prop: "shippingNoteNumber",
              width: "250"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "分段分单号",
              align: "center",
              prop: "serialNumber",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "设备号",
              align: "center",
              prop: "device",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "客户单号",
              align: "center",
              prop: "transportBillNumber",
              width: "250"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              align: "center",
              prop: "createTime",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.createTime)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "运单状态",
              align: "center",
              prop: "shippingStatus",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dict-tag", {
                      attrs: {
                        options: _vm.shippingStatuses,
                        value: scope.row.shippingStatus
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "司机姓名",
              align: "center",
              prop: "driverName",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "司机电话",
              align: "center",
              prop: "driverPhone",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "车牌号",
              align: "center",
              prop: "vehicleNumber",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "装货地址",
              align: "center",
              prop: "uploadAddress",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "装货地触发时间",
              align: "center",
              prop: "uploadTime",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.uploadTime)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "卸货地址",
              align: "center",
              prop: "receiptAddress",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "卸货地触发时间",
              align: "center",
              prop: "receiptTime",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.receiptTime)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "",
              align: "center",
              width: "150",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "text",
                          icon: "el-icon-location"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showTrack(scope.row)
                          }
                        }
                      },
                      [_vm._v("运单轨迹")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        attrs: { size: "small" },
                        on: {
                          command: function(command) {
                            return _vm.handleCommand(command, scope.row)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(" 更多"),
                          _c("i", {
                            staticClass: "el-icon-d-arrow-right el-icon--left"
                          })
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: !scope.row.vehicleNumber,
                                  command: "toVehicle",
                                  icon: "el-icon-view"
                                }
                              },
                              [_vm._v("查看车辆绑定")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: !scope.row.device,
                                  command: "toDevice",
                                  icon: "el-icon-view"
                                }
                              },
                              [_vm._v("查看设备绑定")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total >= 0,
            expression: "total>=0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100, 200, 500, 1000, 2000]
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList
        },
        scopedSlots: _vm._u([
          {
            key: "end",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "warning",
                      icon: "el-icon-download",
                      size: "small",
                      loading: _vm.exportLoading
                    },
                    on: { click: _vm.handleExportLocal }
                  },
                  [_vm._v("导出当前页 ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("baidu-map-track", {
        ref: "mapTrack",
        model: {
          value: _vm.dialogMapTrackVisible,
          callback: function($$v) {
            _vm.dialogMapTrackVisible = $$v
          },
          expression: "dialogMapTrackVisible"
        }
      }),
      _c("batch-Delete", {
        ref: "batchDelete",
        model: {
          value: _vm.open11,
          callback: function($$v) {
            _vm.open11 = $$v
          },
          expression: "open11"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }